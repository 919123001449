define('m07/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  Ember['default'].deprecate = function () {};

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({

    version: "##version##",

    loggedIn: true,

    wuerth: false,
    swg: true,

    environment: "m07swg", // "offline" = offline Version, "m07" = default würth, "m07dev" = würth dev,  "m07swg" = default swg,  "m07swgdev" = swg dev

    initialized: false,
    calculationid: "",
    debug: true,
    xml: false,
    projektdatenInvalid: false,
    traegerInvalid: true,
    auflagerInvalid: true,
    lasteinwirkungInvalid: true,
    verbindungsmittelInvalid: true,
    schraubenausrichtungInvalid: false,
    geometrieInvalid: false,
    bauteileInvalid: true,
    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "https://www.timberconnect.de/produktinfo/wuerth/katalog/katalog_de.pdf",
    eta: "https://www.timberconnect.de/produktinfo/wuerth/eta/eta-110190_de.pdf",
    unsaved: true,

    i18n: inject.service(),
    current: computed.readOnly('i18n.locale'),

    stahl: false,

    bemessungsvorschrift: "EC5 (DE)",

    server: "",
    pfad: "",

    username: '',
    sessionid: '',

    si: "DE",
    so: "DE",
    kl: "DE",
    kat: "DE",
    lc: "EC 1 NAD (DE)",
    ln: "DE",
    tc: "EC 5 NAD (DE)",
    tn: "DE",

    languages: {
      "DE": "deutsch",
      "DK": "dansk",
      "EN": "englisch",
      "FR": "franzoesisch",
      "IT": "italienisch",
      "NL": "nederlands",
      "PL": "polnisch",
      "ES": "spanisch",
      "FI": "suomi",
      "SE": "schwedisch",
      "PT": "portugiesich",
      "CZ": "tschechisch"
    },

    countries: {
      "CL": "chile",
      "DK": "daenemark",
      "DE": "deutschland",
      "EN": "england",
      "UK": "england",
      "FI": "finnland",
      "FR": "frankreich",
      "IE": "irland",
      "NL": "niederlande",
      "IT": "italien",
      "LU": "luxemburg",
      "HR": "kroatien",
      "NO": "norwegen",
      "AT": "oesterreich",
      "PL": "polen",
      "RO": "rumaenien",
      "SE": "schweden",
      "CH": "schweiz",
      "SK": "slowakei",
      "SI": "slowenien",
      "ES": "spanien",
      "CZ": "tschechien",
      "HU": "ungarn",
      "PT": "portugal",
      "BE": "belgien"

    },

    selectedLanguage: "deutsch",
    selectedCountry: "deutschland",

    wuerthlogo: true,

    init: function init() {
      var self = this;
      this._super();

      var username = self.getParameterByName('user_name');
      var sessionid = self.getParameterByName('user_sessionid');

      self.setCountryVars();

      self.set('username', username);
      self.set('sessionid', sessionid);

      var environment = externalConfig.environments[self.get('environment')];
      var url = externalConfig[environment].uriAuthentifizierung;

      $.ajax({
        type: "POST",
        //url: "https://127.0.0.1:8000/Nutzerverwaltung/authentifiziereSession/",
        url: url,
        data: {
          username: username,
          sessionid: sessionid
        }
      }).done(function (res) {
        var authenticationResult = res.split(";");
        console.log(authenticationResult);
        var auth = parseInt(authenticationResult[0]);
        var level = parseInt(authenticationResult[1]);
        console.log(authenticationResult);
        if (auth == -1) {
          console.log("login unsuccessful.");
        } else if (auth == 0) {
          self.set('loggedIn', true);
          if (level == 0) {
            self.set('bauderMaster', false);
          } else if (level >= 1) {
            self.set('bauderMaster', true);
          }
          var applicationdata = self.get('model').application.objectAt(0);
          applicationdata.set('userName', username);
          console.log("login successful!");
        }
        var applicationdata = self.get('model').application.objectAt(0);

        // applicationdata.set('userName', username);
        applicationdata.set('spracheinput', self.get('si'));
        self.send('setSpracheInput', self.get('si'));

        applicationdata.set('spracheoutput', self.get('so'));
        applicationdata.set('kennungland', self.get('kl'));
        applicationdata.set('katalog', self.get('kat'));
        applicationdata.set('loadsCode', self.get('lc'));
        applicationdata.set('loadsNA', self.get('ln'));
        applicationdata.set('timberCode', self.get('tc'));
        applicationdata.set('timberNA', self.get('tn'));

        self.set('selectedCountry', self.get('i18n').t(self.countries[self.get('kl')]));

        var server = externalConfig[environment].server;
        var pfad = externalConfig[environment].pfad;

        // $.ajax({
        //   type: "POST",
        //   url: server + "m07/DE/initialisiereGUI/" +self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
        //   data: {
        //
        //   }
        // }).done(function(data, statusText, xhr) {

        self.controllerFor('supercontroller').logaufruf("initialisiereGUI", 200);

        //   console.log('initialisiereGUI data: ');
        //   console.log(data);
        //
        // })
      });

      self.store.createRecord('bauteile', {});
      self.store.createRecord('lasteinwirkung', {});
      self.store.createRecord('anschlussgeometrie', {});
      self.store.createRecord('projektdaten', {});

      self.transitionToRoute('projektdaten');

      var environment = externalConfig.environments[self.environment];

      console.log('self.environment: ' + self.environment);
      console.log('environment: ' + environment);

      self.set('server', externalConfig[environment].server);
      self.set('pfad', externalConfig[environment].pfad);

      self.get('i18n').addTranslations('cz', externalTranslations.cz);
      self.get('i18n').addTranslations('de', externalTranslations.de);
      self.get('i18n').addTranslations('dk', externalTranslations.dk);
      self.get('i18n').addTranslations('ee', externalTranslations.ee);
      self.get('i18n').addTranslations('en', externalTranslations.en);
      self.get('i18n').addTranslations('es', externalTranslations.es);
      self.get('i18n').addTranslations('fi', externalTranslations.fi);
      self.get('i18n').addTranslations('fr', externalTranslations.fr);
      self.get('i18n').addTranslations('gr', externalTranslations.gr);
      self.get('i18n').addTranslations('hr', externalTranslations.hr);
      self.get('i18n').addTranslations('hu', externalTranslations.hu);
      self.get('i18n').addTranslations('it', externalTranslations.it);
      self.get('i18n').addTranslations('lt', externalTranslations.lt);
      self.get('i18n').addTranslations('lv', externalTranslations.lv);
      self.get('i18n').addTranslations('nl', externalTranslations.nl);
      self.get('i18n').addTranslations('no', externalTranslations.no);
      self.get('i18n').addTranslations('pl', externalTranslations.pl);
      self.get('i18n').addTranslations('pt', externalTranslations.pt);
      self.get('i18n').addTranslations('ro', externalTranslations.ro);
      self.get('i18n').addTranslations('se', externalTranslations.se);
      self.get('i18n').addTranslations('si', externalTranslations.si);
      self.get('i18n').addTranslations('sk', externalTranslations.sk);
    },

    getParameterByName: function getParameterByName(name, url) {

      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    setCountryVars: function setCountryVars() {

      var self = this;

      var si = self.getParameterByName('spracheinput');
      var so = self.getParameterByName('spracheoutput');
      var kl = self.getParameterByName('kennungland');
      var kat = self.getParameterByName('katalog');
      var lc = self.getParameterByName('loadsCode');
      var ln = self.getParameterByName('loadsNA');
      var tc = self.getParameterByName('timberCode');
      var tn = self.getParameterByName('timberNA');

      if (window.location.href.indexOf("timberCode") == -1) {
        self.set('si', "DE");
        self.set('so', "DE");
        self.set('kl', "DE");
        self.set('kat', "DE");
        // self.set('lc', "EC 1 NAD (DE)");
        self.set('lc', "EC1");
        self.set('ln', "DE");
        // self.set('tc', "EC 5 NAD (DE)");
        self.set('tc', "EC5");
        self.set('tn', "DE");
        self.set('bemessungsvorschrift', "EC5 (DE)");
      } else {
        self.set('si', si);
        self.set('so', so);
        self.set('kl', kl);
        self.set('kat', kat);
        self.set('lc', lc);
        self.set('ln', ln);
        self.set('tc', tc);
        self.set('tn', tn);

        if (tc.slice(10, tc.length - 1).length === 2) {
          self.set('bemessungsvorschrift', "EC5 (" + tc.slice(10, tc.length - 1) + ")");
        } else {
          if (tc.slice(10, tc.length - 1) === "D") {
            self.set('bemessungsvorschrift', "EC5 (DE)");
          } else if (tc.slice(10, tc.length - 1) === "A") {
            self.set('bemessungsvorschrift', "EC5 (AT)");
          } else if (tc.slice(10, tc.length - 1) === "I") {
            self.set('bemessungsvorschrift', "EC5 (IT)");
          } else if (tc.slice(10, tc.length - 1) === "F") {
            self.set('bemessungsvorschrift', "EC5 (FR)");
          }
        }

        //self.send('setSpracheInput', si);
      }
    },

    getStahlname: function getStahlname(index) {
      var self = this;
      var pklassenDB = fklDB.fkl_stahl;

      var tmp = "";
      var len = pklassenDB.length;

      for (var i = 0; i < len; i++) {
        if (pklassenDB[i]["fklind"] === index) {
          tmp = pklassenDB[i]["fkl"];
        }
      }
      return tmp;
    },

    resetApplication: function resetApplication() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      self.transitionToRoute('projektdaten');
    },

    actions: {

      setSpracheInput: function setSpracheInput(spracheInput) {
        var self = this;
        this.get('i18n').set('locale', spracheInput.toLowerCase());
        var applicationdata = this.get('model').application.objectAt(0);
        applicationdata.set('spracheinput', spracheInput);
        applicationdata.set('spracheoutput', spracheInput);
        self.send('setCountryCode', spracheInput);

        self.set('selectedLanguage', self.get('i18n').t(self.languages[spracheInput]));

        if (spracheInput == "DE") {
          self.set('hilfelink', 'https://timberconnect.de/hilfe/wuerth/html/de/m07___zugscherverbindung.html?ms=AAAA&st=MA%3D%3D&sct=Nzcz&mw=MzAw#'); // Würth
        } else {
            self.set('hilfelink', 'https://timberconnect.de/hilfe/wuerth/html/en/m07___tensile_shear_connection.html?ms=AAAA&st=MA%3D%3D&sct=MjU1&mw=MzAw#'); // Würth
          }

        this.controllerFor('impressum').set('selectedLanguage', spracheInput);

        this.controllerFor('geometrie').setSelectFieldsContent();
        this.controllerFor('bauteile').setSelectFieldsContent();
        this.controllerFor('schraubenausrichtung').setSelectFieldsContent();
        this.controllerFor('lasteinwirkung').setSelectFieldsContent();

        this.controllerFor('bauteile').set("initTrigger", !this.controllerFor('geometrie').set("initTrigger"));

        self.resetApplication();
      },

      setCountryCode: function setCountryCode(countryCode) {

        countryCode = countryCode === "EN" ? "UK" : countryCode;

        var self = this;
        var applicationdata = this.get('model').application.objectAt(0);
        applicationdata.set('kennungland', countryCode);

        var firm = applicationdata.get('firm');

        var projektdaten = this.controllerFor('projektdaten');

        if (countryCode === "DE") {
          projektdaten.set('plzservice', true);
        } else {
          projektdaten.set('plzservice', false);
        }

        var downloadcenter = this.controllerFor('downloadcenter');

        var cC = countryCode;
        var eta = "120196";

        if (typeof linksDB.firm.findBy('id', firm) === "undefined") {
          firm = "SWG";
        }

        if (typeof linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC) === "undefined") {
          cC = "EN";
        }

        if (firm === "WUERTH") {
          eta = "110190";
        }

        var prodkaturl = "";
        var etaurl = "";

        if (self.get('environment') === "offline") {
          prodkaturl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", "katalog").offline;
          etaurl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", eta).offline;
        } else {
          prodkaturl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", "katalog").file;
          etaurl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", eta).file;
        }

        this.set('eta', etaurl);
        this.set('produktkatalog', prodkaturl);
        downloadcenter.set('eta', etaurl);
        downloadcenter.set('produktkatalog', prodkaturl);

        self.set('selectedCountry', self.get('i18n').t(self.countries[countryCode]));

        self.set('wuerthlogo', cC === "DE" ? true : false);

        self.resetApplication();
      },

      setETA: function setETA(eta) {
        var applicationdata = this.get('model').application.objectAt(0);

        var etaurl = "assets/public/docs/eta_" + eta.toLowerCase() + ".pdf";

        this.set('eta', etaurl);
      },

      setBemessungsvorschrift: function setBemessungsvorschrift(bemessungsvorschrift) {
        var self = this;
        var applicationdata = this.get('model').application.objectAt(0);

        if (bemessungsvorschrift === "DE" || bemessungsvorschrift === "AT" || bemessungsvorschrift === "SE") {
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', bemessungsvorschrift);
        } else {
          if (bemessungsvorschrift === "DK" || bemessungsvorschrift === "FI") {
            // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
            // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
            applicationdata.set('loadsNA', "DE");
            applicationdata.set('timberNA', bemessungsvorschrift);
          } else {
            // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
            if (bemessungsvorschrift === "IT") {
              // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
              applicationdata.set('loadsNA', "DE");
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set('timberNA', bemessungsvorschrift);
            } else if (bemessungsvorschrift === "FR") {
              // applicationdata.set('loadsCode', "EC 1 NAD ("+ bemessungsvorschrift +")");
              applicationdata.set('loadsNA', bemessungsvorschrift);
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set('timberNA', bemessungsvorschrift);
            }
          }
        }

        applicationdata.set('loadsCode', "EC1");
        applicationdata.set('timberCode', "EC5");

        this.set('bemessungsvorschrift', "EC5 (" + bemessungsvorschrift + ")");

        var lasteinwirkung = this.controllerFor('lasteinwirkung');
        lasteinwirkung.set('timberNA', bemessungsvorschrift);
        lasteinwirkung.setSelectFieldsContent();

        self.resetApplication();
      },

      downloadcenter: function downloadcenter(spracheInput) {
        this.transitionToRoute('downloadcenter');
      },

      verbindungsmittel: function verbindungsmittel(event) {
        console.log('test');
        var lasteinwirkung = this.controllerFor('lasteinwirkung');
        var applicationdata = this.get('model').application.objectAt(0);

        if (!applicationdata.get('treeLoaded')) {
          console.log("jetzt müsste der Tree neu geladen werden.");
          lasteinwirkung.send('proceedInput');
        } else {
          console.log("jetzt müsste der Tree NICHT neu geladen werden.");
        }
      },

      ergebnis: function ergebnis(event) {
        console.log('test');
        var verbindungsmittel = this.controllerFor('verbindungsmittel');

        verbindungsmittel.send('proceedInput');
      },

      newAction: function newAction(event) {
        console.log(window.location.href);
        var self = this;
        var protocol = window.location.protocol;
        var host = window.location.host;

        var applicationdata = this.get('model').application.objectAt(0);
        var si = applicationdata.get('spracheinput');
        var so = applicationdata.get('spracheoutput');
        var kl = applicationdata.get('kennungland');
        var kat = applicationdata.get('katalog');
        var lc = applicationdata.get('loadsCode');
        var ln = applicationdata.get('loadsNA');
        var tc = applicationdata.get('timberCode');
        var tn = applicationdata.get('timberNA');
        var bv = self.get('bemessungsvorschrift');

        window.location.href = protocol + "//" + host + "/?user_name=" + self.get('username') + "&user_sessionid=" + self.get('sessionid') + "&spracheinput=" + si + "&spracheoutput=" + so + "&kennungland=" + kl + "&katalog=" + kat + "&loadsCode=" + lc + "&loadsNA=" + ln + "&timberCode=" + tc + "&timberNA=" + tn + "&bemessungsvorschrift=" + bv;
      },

      contactAction: function contactAction(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      produktkatalogAnzeigen: function produktkatalogAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      etaAnzeigen: function etaAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      setCameraActiv: function setCameraActiv(camera) {
        var lastCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', this.get('model').x3ddefault.objectAt(0).get('lastViewpoint'));

        this.get('model').x3ddefault.objectAt(0).set('lastViewpoint', camera);

        var activeCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', camera);

        lastCamera.get('cameraProperties').objectAt(0).set('setBind', false);
        activeCamera.get('cameraProperties').objectAt(0).set('setBind', true);

        // resetViewpoint() zu finden in /bower_components/viewpointmanagement/vpmanagements.js
        // setzt die viewpoints zurück
        var self = this;
        self.zentriertObjekt();

        this.get('model').x3ddefault.objectAt(0).set('transformHelper', !this.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      },

      setDrahtgittermodel: function setDrahtgittermodel() {
        this.get('model').x3ddefault.objectAt(0).set('istGitterModell', !this.get('model').x3ddefault.objectAt(0).get('istGitterModell'));
      },

      saveAction: function saveAction() {

        console.log('speichern()');

        var self = this;

        self.transitionToRoute('downloadcenter');
        var dc = self.controllerFor('downloadcenter');
        dc.set('savingInProgress', true);

        var application = self.controllerFor('application');

        var bauteile = self.controllerFor('bauteile');

        var applicationdata = application.get('model').application.objectAt(0);
        var applicationdataJSON = JSON.stringify(applicationdata);

        var projektdatendata = application.get('model').projektdaten.objectAt(0);
        var projektdatendataJSON = JSON.stringify(projektdatendata);

        // var geometriedata = application.get('model').geometrie.objectAt(0);
        // var geometriedataJSON = JSON.stringify(geometriedata);
        //
        // var bauteiledata = application.get('model').bauteile.objectAt(0);
        // var bauteiledataJSON = JSON.stringify(bauteiledata);
        //
        // var schraubenausrichtungdata = application.get('model').schraubenausrichtung.objectAt(0);
        // var schraubenausrichtungdataJSON = JSON.stringify(schraubenausrichtungdata);
        //
        // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
        // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
        //
        // if (self.debug) {
        //   console.log("projektdatendata: " + projektdatendataJSON);
        //   console.log("applicationdataJSON: " + applicationdataJSON);
        //   console.log("geometriedataJSON: " + geometriedataJSON);
        //   console.log("bauteiledataJSON: " + bauteiledataJSON);
        //   console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
        // }
        //
        // var uebergabedaten = $.extend(true, JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(bauteiledataJSON), JSON.parse(schraubenausrichtungdataJSON), JSON.parse(lasteinwirkungdataJSON));
        //
        // var shearconnection = {
        //       "ConnectionTyp": geometriedata.get('ConnectionType'),
        //       "von1zu2": true,
        //       "ZwischenSchichtVorh": false,
        //       "VerstaerkungVorh": false,
        //       "ShearPlaneMultiplicator": geometriedata.get('ShearPlaneMultiplicator'),
        //       "Setztiefe": geometriedata.get('Setztiefe'),
        //       "ShowMessagesOnError": false
        //     };
        //
        //
        //     if (self.get('stahl')) {
        //       var timberelement1 = {
        //         "Querschnittswerte": {
        //           "t_Fastener": bauteiledata.get('TimberElement2_b'),
        //           "b": bauteiledata.get('TimberElement2_b'),
        //           "h": bauteiledata.get('TimberElement2_h'),
        //           "QuerschnittsID": 1
        //         },
        //         // "ALFA_RAD_LoadGrain": '0.0',
        //         // "Predrilled": geometriedata.get('Predrilled')
        //       };
        //     } else {
        //       var timberelement1 = {
        //         "Querschnittswerte": {
        //           "t_Fastener": bauteiledata.get('TimberElement1_b'),
        //           "b": bauteiledata.get('TimberElement1_b'),
        //           "h": bauteiledata.get('TimberElement1_h'),
        //           "QuerschnittsID": 1
        //         },
        //         // "ALFA_RAD_LoadGrain": '0.0',
        //         // "Predrilled": geometriedata.get('Predrilled')
        //       };
        //     }
        //
        //     var timberelement2 = {
        //       "Querschnittswerte": {
        //         "t_Fastener": bauteiledata.get('TimberElement2_b'),
        //         "b": bauteiledata.get('TimberElement2_b'),
        //         "h": bauteiledata.get('TimberElement2_h'),
        //         "QuerschnittsID": 1
        //       },
        //       // "ALFA_RAD_LoadGrain": '0.0',
        //       // "Predrilled": geometriedata.get('Predrilled')
        //     };
        //
        //     var interlayerelement = {
        //       "Querschnittswerte": {
        //         "t_Fastener": '0.0',
        //         "b": '0.0',
        //         "h": '0.0',
        //         "QuerschnittsID": 1
        //       },
        //       // "ALFA_RAD_LoadGrain": '0.0',
        //       // "Predrilled": false
        //     };
        //
        //     var steelplate = {
        //       "Querschnittswerte": {
        //         "t_Fastener": bauteiledata.get('TimberElement1_b'),
        //         "b": bauteiledata.get('TimberElement1_b'),
        //         "h": bauteiledata.get('TimberElement1_h')
        //       },
        //       "Bezeichnung": self.getStahlname(Number(bauteile.get('htfkl'))),
        //       "f_yk": Number(bauteile.get('htfkl')).toFixed(1),
        //     };
        //
        // uebergabedaten.ShearConnection = shearconnection;
        // uebergabedaten.TimberElement1 = timberelement1;
        // uebergabedaten.TimberElement2 = timberelement2;
        // uebergabedaten.InterlayerElement = interlayerelement;
        // uebergabedaten.SteelPlate = steelplate;
        //
        // var JSONdata = JSON.stringify(uebergabedaten);
        var JSONdata = JSON.stringify(self.controllerFor('supercontroller').uebergabedatenErzeugen());

        var initialized = applicationdata.get('initialized');

        var parasToSave = JSON.parse(JSONdata);

        var dataToSave = JSON.stringify(auxiliary.save([applicationdata.data, projektdatendata.data, parasToSave]));

        console.log(JSON.stringify(dataToSave));

        //var dingsbums = JSON.stringify(dataToSave);

        if (!initialized) {

          $.ajax({
            type: "POST",
            url: self.server + "initialisiereBerechnung/",
            //url: "https://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
            data: {
              kennung: applicationdataJSON,
              // projektdaten: projektdatendataJSON,
              paras: JSONdata,
              modul: "m07",
              pfad: self.pfad
            }
          }).done(function (data, statusText, xhr) {

            self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

            var cid = data;
            applicationdata.set('Calculation_ID', cid);
            applicationdata.set('initialized', true);
            dc.set('cid', data);

            $.ajax({
              type: "POST",
              url: self.server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          });
        } else {

            var cid = applicationdata.get('Calculation_ID');

            $.ajax({
              type: "POST",
              url: self.server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          }

        self.set('unsaved', false);
      },

      /**
       * This action is called when a file has been loaded.
       *
       * @method ACTION: fileLoaded
       */
      fileLoaded: function fileLoaded(file) {

        var self = this;

        if (self.xml) {
          var test = self.parseXml(file.data);
          console.log('test');
          console.log(xml2json(test, ""));
        } else {
          var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);

          var projektdaten = self.controllerFor('projektdaten');
          var lasteinwirkung = self.controllerFor('lasteinwirkung');
          var bauteile = self.controllerFor('bauteile');
          // var anschlussgeometrie = self.controllerFor('anschlussgeometrie');
          var geometrie = self.controllerFor('geometrie');
          var schraubenausrichtung = self.controllerFor('schraubenausrichtung');

          var values = JSON.parse(file.data);

          self.send('setSpracheInput', values.spracheinput);

          applicationdata.set('katalog', values.katalog);

          self.send('setBemessungsvorschrift', values.timberNA);

          applicationdata.set('kennungland', values.kennungland);
          applicationdata.set('userID', values.userID);
          applicationdata.set('userName', values.userName);

          projektdaten.setValues(JSON.parse(file.data));
          lasteinwirkung.setValues(JSON.parse(file.data));
          geometrie.setValues(JSON.parse(file.data));
          bauteile.setValues(JSON.parse(file.data)); // wegen x3d aktuell noch auskommentiert.
          schraubenausrichtung.setValues(JSON.parse(file.data));
        }
      },

      toggleForm: function toggleForm() {
        $('.clearfix').toggleClass('formZu');
        $('#formToggler').toggleClass('open');

        setTimeout(function () {
          var x3dGrafik = document.getElementById("threedee");
          var x3d = document.getElementById("x3d");
          var canvas = document.getElementsByTagName("canvas");
          var x3druntime = document.getElementById('x3d').runtime;

          // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
          x3d.setAttribute('height', Number($(window).height()) - 300);
        }, 400);
      }
    },

    miniertX3D: function miniertX3D() {
      $('.clearfix').removeClass('formZu');
      $('#formToggler').removeClass('open');

      setTimeout(function () {
        var x3dGrafik = document.getElementById("threedee");
        var x3d = document.getElementById("x3d");
        var canvas = document.getElementsByTagName("canvas");
        var x3druntime = document.getElementById('x3d').runtime;

        // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
        x3d.setAttribute('height', Number($(window).height()) - 300);
      }, 400);
    },

    parseXml: function parseXml(xml) {

      var dom = null;
      if (window.DOMParser) {
        try {
          dom = new DOMParser().parseFromString(xml, "text/xml");
        } catch (e) {
          dom = null;
        }
      } else if (window.ActiveXObject) {
        try {
          dom = new ActiveXObject('Microsoft.XMLDOM');
          dom.async = false;
          if (!dom.loadXML(xml)) // parse error ..

            window.alert(dom.parseError.reason + dom.parseError.srcText);
        } catch (e) {
          dom = null;
        }
      } else alert("cannot parse xml string!");
      return dom;
    },

    vWinkelEingetragen: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);

      if (x3d.get('vWinkelEingetragen') && Number(x3d.get('vWinkel')) !== 0) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.vWinkel', 'model.x3ddefault.firstObject.vWinkeleingetragen'),

    zentriertObjekt: function zentriertObjekt() {
      var self = this;
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = Number(x3d.get('ueberstand'));

      var x = 0,
          y = 0,
          z = 0,
          distanz = 0;

      var hoeheMasskette = 0.5;
      var textgroesse = 0.3 * Number(x3d.get('skalierungsfaktor'));
      var winkelBegrenzung = 1;
      var laengeLastenpfeil = 1.5;

      if (x3d.get('ergebnisGeladen') === true) {

        var dimensionen = x3d.get('dimensionen');

        x = Number(this.getAbstandPunkte(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('x'), dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('x'))) / 10 + (2 * hoeheMasskette + 2 * textgroesse + winkelBegrenzung + laengeLastenpfeil);
        y = Number(this.getAbstandPunkte(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('y'), dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('y'))) / 10 + (2 * hoeheMasskette + 2 * textgroesse + winkelBegrenzung + laengeLastenpfeil) * Math.sin(x3d.get('vWinkel') * Math.PI / 180);
        z = Number(this.getAbstandPunkte(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('z'), dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('z'))) / 10 + (2 * hoeheMasskette + 2 * textgroesse + winkelBegrenzung + laengeLastenpfeil);
      } else {
        x = 2 * (x3d.get('xMittelbauteil') / 10 + x3d.get('xSeitenbauteil') / 10 + textgroesse + hoeheMasskette) + winkelBegrenzung;
        var y1 = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 10;
        var y2 = Math.sin(x3d.get('vWinkel') * Math.PI / 180) * (laengeLastenpfeil + winkelBegrenzung + textgroesse + hoeheMasskette + Number(x3d.get('xMittelbauteil')) + Number(x3d.get('xSeitenbauteil'))) / 10;
        y = this.arrayAbsteigendSortieren([y1, y2])[0];
        z = 2 * bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10;
      }

      var arr = [];
      var fieldOfViewValue = 0,
          d = 0;

      if (bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind') === true) {
        arr = this.arrayAbsteigendSortieren([x, y, z]);

        var val = Number(arr[0]);

        fieldOfViewValue = Math.sqrt(Math.pow(val, 2) + Math.pow(val * 0.4, 2) + Math.pow(val, 2));

        if (x3d.get('ergebnisGeladen') === true) {
          fieldOfViewValue = fieldOfViewValue * 0.6;
        }
      } else {

        arr = this.arrayAbsteigendSortieren([x, y, z]);

        fieldOfViewValue = arr[0];
      }

      d = this.arrayAbsteigendSortieren([x, y, z])[0];

      x3d.set('aktuelleDistance', d);

      var canvasWidth = Number(document.getElementById('x3d').runtime.getWidth());
      var canvasHeigth = Number(document.getElementById('x3d').runtime.getHeight());

      var quot = canvasWidth / canvasHeigth;

      if (x / quot < y) {
        quot = 1;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));

      resetViewpoint(fieldOfViewValue / 2 / quot, d);
    },

    ergebnisGeladen: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var value = x3d.get('ergebnisGeladen');
      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen'),

    abstaendeAnzeigen: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var value = x3d.get('abstaendeAnzeigen');
      return value;
    }).property('model.x3ddefault.firstObject.abstaendeAnzeigen'),

    einschraubwinkelAnzeigen: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var value = !x3d.get('ergebnisGeladen');
      return value;
    }).property('model.x3ddefault.firstObject.ergebnisGeladen'),

    plattenListe: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var platesArray = Ember['default'].Set.create();
      console.log('Platten');

      bauteile.forEach(function (item) {
        if (item.get('typ') === "platte") {
          console.log(item.id);
          platesArray.add(item);
        }
      });

      return platesArray.toArray();
    }).property('model.x3ddefault.firstObject.transformHelper'),

    ergebnisMassketten: (function () {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var masskettenArray = Ember['default'].Set.create();

      if (x3d.get('ergebnisGeladen') === true) {
        bauteile.forEach(function (item) {
          if (item.get('typ') === "masskette" && item.get('masskette').objectAt(0).get('masstext') !== "0.00" && item.get('masskette').objectAt(0).get('masstext') !== "0.0") {

            // if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true && (item.id.indexOf('Parallel') !== -1)) {
            //   masskettenArray.add(item);
            // }
            //
            // if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true && (item.id.indexOf('Senkrecht') !== -1) && (item.id.indexOf('Diagonale') === -1 || Number(x3d.get('vWinkel') === 0))) {
            //   masskettenArray.add(item);
            // }
            //
            // if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true && (item.id.indexOf('Senkrecht') !== -1 || item.id.indexOf('Parallel') !== -1)) {
            //   masskettenArray.add(item);
            // }
            //
            // if (bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind') === true) {
            //   masskettenArray.add(item);
            // }

            masskettenArray.add(item);
          }
        });
      }

      return masskettenArray.toArray();
    }).property('model.x3ddefault.firstObject.schraubenlisteAktualisiert', 'model.x3ddefault.firstObject.viewpointHelper', 'model.x3ddefault.firstObject.lastViewpoint'),

    scherkraftEingetragen: (function () {
      var value = false;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);

      if (x3d.get('scherkraftEingetragen') && Number(x3d.get('scherkraft')) !== '0.0' && !x3d.get('ergebnisGeladen')) {
        value = true;
      }

      return value;
    }).property('model.x3ddefault.firstObject.scherkraftEingetragen', 'model.x3ddefault.firstObject.ergebnisGeladen'),

    sceneTranslation: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var x = 0,
          y = 0,
          z = 0;

      if (x3d.get('ergebnisGeladen') === false) {

        var arr = [Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')) / 2, Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')), Math.sin(Number(x3d.get('vWinkel')) * Math.PI / 180) * Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x'))];

        arr.sort(function (a, b) {
          return b - a;
        });

        y = Number(arr[0] / 20);
      } else {
        var dimensionen = x3d.get('dimensionen');
        x = (Number(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('x')) + Number(dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('x'))) / 20;
        y = (Number(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('y')) + Number(dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('y'))) / 20;
        z = (Number(dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').get('z')) + Number(dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').get('z'))) / 20;
      }

      var translation = -x + ' ' + -y + ' ' + -z;

      return translation;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    eingabenUngueltig: (function () {
      var self = this;
      var value = true;

      if (self.get('projektdatenInvalid') === false && self.get('geometrieInvalid') === false && self.get('bauteileInvalid') === false && self.get('schraubenausrichtungInvalid') === false && self.get('lasteinwirkungInvalid') === false) {
        value = false;
      }

      return value;
    }).property('projektdatenInvalid', 'geometrieInvalid', 'bauteileInvalid', 'schraubenausrichtungInvalid', 'lasteinwirkungInvalid'),

    setMinMaxWerte: function setMinMaxWerte(bezeichner, array) {
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var dimensionen = x3d.get('dimensionen');

      array.sort(function (a, b) {
        return a - b;
      });

      dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').set(bezeichner, Number(array[0]));
      dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').set(bezeichner, Number(array[array.length - 1]));
    },

    arrayAbsteigendSortieren: function arrayAbsteigendSortieren(arr) {
      var ergebnis = arr.sort(function (a, b) {
        return b - a;
      });

      return ergebnis;
    },

    getAbstandPunkte: function getAbstandPunkte(x1, x2) {
      return Math.abs(x1 - x2);
    },

    getSumme: function getSumme(x1, x2) {
      return Math.abs(x1 + x2);
    }

  });

});